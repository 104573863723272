.Title{
    text-align: center;
    color: #353B51;
    cursor: pointer;
    font-size: 29px
}

.ContentCard-Text{
    text-align: center;
    font-size: 16px;
    margin: 0px;
    padding: 0px
}

.ContentCard-Label{
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    padding: 8px;
    margin: 0px;
    border-bottom: 1px solid #707070;
}

.ContentCard-C-Label{
    text-align: left;
    margin: 0px;
    padding: 5px 0 0 5px;
    font-size: 13px
}

.ContentCard-J-Label{
    text-align: right;
    margin: 0px;
    padding: 5px 5px 0 0;
    font-size: 13px
}

.ContentCard-Border{
    border: 1px solid #707070
}

.ContentCard-BorderSplit{
    border-right: 1px solid #707070;
}

.Divider{ 
    background-color: #707070;
    height: 3px;
    margin-top: 20px;
}

.Add-Paper{ 
    margin: 0 20px 0 20px;
    border: 1px solid #707070   
}

.Word-Count{ 
    text-align: right;
    margin: 0px;
    font-size: 13px;
}

.Shorten-Message{
    text-align: left;
    margin: 0px;
    font-size: 13px;
    color: red;
}

.Display-Message{
    display: flex;
    justify-content: space-between;
}

.Add-LeftShadedColumn{ 
    border-top: 1px solid #707070;
    background-color: #DAE0EC;
}

.Add-RightColumn{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-left: 1px solid #707070;
}

.centered {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
}